.coming {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 72px;
    color: aliceblue;
    animation: blink 5s infinite;
}

@keyframes blink{
    0% {
      color: aliceblue;
    }
    20% {
      color: lightyellow;
    }
    40% {
        color: yellow;
    }
    60% {
        color: lightgreen;
    }
    80% {
        color: lightsalmon;
    }
    100% {
      backgrcoloround: aliceblue;
    }
  }
  
  @-webkit-keyframes blink{
    0% {
        color: aliceblue;
    }
    20% {
        color: lightyellow;
    }
    40% {
        color: yellow;
    }
    60% {
        color: lightgreen;
    }
    80% {
        color: lightsalmon;
    }
    100% {
        color: aliceblue;
    }
  }