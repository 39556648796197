.items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .25);
    width: 95%;
    height: 80px;
    border-radius: 13px;
    background: whitesmoke;
    /* Making it static */
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    /* Put always on the top */
    z-index: 9999;
}

.logo {
    color: #555;
    font-size: 1.9rem;
    cursor: pointer;
    background-color: red;
  
    /* Create the gradient. */
    background-image: linear-gradient(45deg, #98ff98 , lightblue, aqua);
    
    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

.menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
}

.nav-links {
    text-decoration: none;
    color: #555;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.nav-links {
    padding-right: 10px;
}

.nav-links:hover {
    background-color: #00BFFF;
    color: #fff;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.nav-links-mobile {
    display: none;
}

.button {
    width: 100px;
    height: 40px;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

button:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

button:active {
    color: #000
}

button:active:after {
    background: transparent;
}

button:hover:before {
    opacity: 1;
}

button:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.icons {
    display: none;
}

@media screen and (max-width: 850px) {
    .items {
        z-index: 99;
    }
    
    .menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: whitesmoke;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        transition: 0.3s ease-in-out;
        border-radius: 13px;
    }

    .menu.active {
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;
    }

    .nav-links {
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 2rem 0;
    }

    .icons {
        display: block;
        cursor: pointer;
    }
}