.container {
    margin: 4rem 6rem;
    color: #2a2a2a;
}

.container form {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
}

.container input {
    height: 3rem;
    padding: 0 1rem;
    margin-bottom: 2rem;
    border-radius: .3rem;
    border: 1px solid #2a2a2a;
}

.container textarea {
    height: 3rem;
    padding: 0 1rem;
    margin-bottom: 2rem;
    border-radius: .3rem;
    border: 1px solid #2a2a2a;
}

input:focus{
    background: #F0F8FF;
}

textarea:focus{
    background: #F0F8FF;
}


@media screen and (max-width: 850px) {
    .container {
        margin: 4rem 2rem;
    }

    .container form {
        padding-top: 2rem;
        width: 90%;
    }
}