.footer {
    padding: 4rem 6rem;
    background-image: linear-gradient(to top right, lightblue,  #6699cc);
    color: #fff;
}

.top {
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.top a {
    color: #fff;
    font-size: 2rem;
    margin-left: 1rem;
}

.bottom {
    padding-top: 2rem;
    text-align: start;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.bottom div {
    display: flex;
    flex-direction: column;
}

.bottom h4 {
    font-size: 1.3rem;
    padding: 1rem 0 .8rem 0;
}

.bottom a {
    text-decoration: none;
    color: #fafafa;
    padding-bottom: .4rem;
    font-size: 1.1rem;
}

.bottom a:hover {
    color:#6699cc;
    font-size: 1.2rem;
    ;
}

.bottom a:hover:before {
    content: '\2192';
}

.bottom a:hover:after {
    content: '\2190';
}

@media screen and (max-width: 850px) {
    .footer {
        padding: 4rem 2rem;
    }

    .top a {
        margin: 1rem 1rem 0 0;
    }

    .bottom div {
        width: 50%;
    }
}