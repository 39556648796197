.container {
    margin: 4rem 6rem;
    text-align: start;
    text-align: justify;
}

.container h1 {
    padding-bottom: 1rem;
}

.container p {
    padding-bottom: 2rem;
    font-size: 1.1rem;
}

@media screen and (max-width: 850px) {
    .container {
        margin: 4rem 2rem;
    }
}